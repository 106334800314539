import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import fireball from '../assets/lightning2.png';
import roadmap from '../assets/Roadmap-subHeader-Yellow.png';

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});

class Roadmap extends Component {


    render() {
        return (

            <div class="rmbg">

                <div class="storyH"><img src={roadmap}/></div>

                <div class="rmConMain">

                <div class="rmConAndImg">

                    <div class="rmCon2" data-aos="fade-up" data-aos-duration="1500">
                        <div class="Q">Q4 2022</div>
                        <div class="rmCon"><img src={fireball}/>Art Completion of 10k collection </div>
                        <div class="rmCon"><img src={fireball}/>Discord fully Completed </div>
                        <div class="rmCon"><img src={fireball}/>Domain name secured PowerOfZeusDAO.com</div>
                        <div class="rmCon"><img src={fireball}/>Start Ads/Spaces on Twitter </div>
                        <div class="rmCon"><img src={fireball}/>Pre-Release Power Of Zeus DAO 10k Collection</div>
                        <div class="rmCon"><img src={fireball}/>Art Completion of 10k collection </div>

                    </div>
                </div>

                <div class="rmConAndImg" data-aos="fade-up" data-aos-duration="1500">
 
                    <div class="rmCon2">
                        <div class="Q">Q1 2023</div>
                        <div class="rmCon"><img src={fireball}/>Non stop Ads</div>
                        <div class="rmCon"><img src={fireball}/>Twitter Space</div>
                        <div class="rmCon"><img src={fireball}/>Giveaways</div>
                        <div class="rmCon"><img src={fireball}/>Official Release of Power Of Zeus DAO</div>
                        <div class="rmCon"><img src={fireball}/>Sell out Power Of Zeus DAO 10k Collection</div>
                        <div class="rmCon"><img src={fireball}/>$80,000 will go into paying for expense's for creation of the project and Paying the staff and myself</div>
                        <div class="rmCon"><img src={fireball}/>Make first 15,000$ invest into a Project that the community votes on</div>
                        <div class="rmCon"><img src={fireball}/>Secure passive income for community wallet</div>
                        <div class="rmCon"><img src={fireball}/>Community brain storming for│ideas for the future of Power Of Zeus DAO</div>
                        <div class="rmCon"><img src={fireball}/>Begin voting process for distribution of community funds</div>
                        <div class="rmCon"><img src={fireball}/>Community brain storming for the future of Power of Zeus Dao</div>

                     </div>
                </div>


                </div>

            </div>


        );

    }
}

export default Roadmap;